<template>
  <v-container>
    <v-row justify="center">
      <v-col class="text-center pt-5">
        <h4 class="text-h4 font-weight-regular">HTTP Traces</h4>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="loading">
      <v-col>
        <div class="text-center py-15">
          <v-progress-circular indeterminate size="50" color="grey" />
          <div class="py-2">Loading</div>
        </div>
      </v-col>
    </v-row>

    <v-row justify="center" v-else>
      <v-col>
        <v-expansion-panels class="http-trace-panel-holder" v-model="activeTab">
          <template v-for="(app, index) in Object.values(applications)">
            <v-expansion-panel class="http-trace-panel" :key="app.id">
              <v-expansion-panel-header class="elevation-1">
                <h3>{{ app.title }}</h3>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <ApplicationHttpTraceCard
                  :key="app.id"
                  :link="app.link"
                  :is-active="index === activeTab"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import startCase from "lodash.startcase";
import * as api from "@/constants/api";
import { serviceNames } from "@/constants/serviceNameMap";

export default {
  name: "HTTPTrace",
  metaInfo: { title: "HTTP Traces" },
  components: {
    ApplicationHttpTraceCard: () =>
      import("@/views/components/Management/ApplicationHttpTraceCard"),
  },
  data: () => ({
    activeTab: null,
    loading: false,
    applications: {},
  }),
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loading = true;

        const applications = {};

        const addApp = ([route, name]) => {
          applications[name] = {
            id: name,
            title: serviceNames[name] || startCase(name),
            link: route.replace("/**", api.ACTUATOR_ROUTES_HTTP_TRACE),
          };
        };

        addApp(["/**", "gateway-service"]);
        const data = await this.$axios.$get(api.ACTUATOR_ROUTES);
        Object.entries(data).forEach(addApp);

        this.applications = applications;
      } catch (err) {
        this.$iziToast.showError(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.http-trace-panel {
  .text--secondary {
    padding: 0.25rem 0;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>

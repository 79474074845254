export const serviceNames = {
  "gateway-service": "Gateway Service",
  "config-server": "Config Server",
  "face-service": "Face Service",
  "fingerprint-service": "Fingerprint Service",
  "nid-scraper": "NID Scraper",
  "ocr-service": "OCR Service",
  //"onboarding-service": "Onboarding Service",
  "onebankapi-service": "Onebankapi Service",
  "porichoy-service": "Porichoy Service",
  "report-service": "Report Service",
  "repository-service": "Repository Service",
  "third-party-service": "Thirdparty Service",
};
